import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import PageHolder from '../views/PageHolder.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/chart',
        component: TabsPage,
        children: [
            {
                path: 'chart',
                component: () => import('@/views/HomePage.vue')
            },
            {
                path: 'my-charger',
                component: () => import('@/views/MyCharger.vue')
            },
            {
                path: 'charge-card',
                component: () => import('@/views/ChargeCard.vue')
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
