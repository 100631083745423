
import {
  IonNav,
  IonPage,
  IonRouterOutlet,
  IonTabs,
  IonRow,
  IonCol,
  IonToolbar,
  IonHeader
} from '@ionic/vue';
import {informationCircle, map} from 'ionicons/icons';
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonNav,
    IonTabs,
    IonPage,

    IonRow,
    IonCol,
    IonRouterOutlet
  },
  setup() {
    return {
      map,
      informationCircle,
    }
  }
})
